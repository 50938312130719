import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

const res_field = `
	id
	createdAt
	updatedAt
	userProjectId
	date
	remark
	strTime
	endTime
	actualEndTime
	lunchStrTime
	lunchEndTime
	lunch
	hotel
	useDinner
	useAbroad
	teleworking
	totMin
	validated
	timesheetId
	laValidatedId
	laValidatedWhen
	lbValidatedId
	lbValidatedWhen
	comment
	stageDayUsed
	dayNumber
	salaryBase
	salary
	salaryOvertime
	salaryHourNight
	salaryHourTooEarly
	totMinOvertime
	leftHomeAt
	returnedHomeAt
	totMinNight
	totMinTooEarly
	dayType
	dayRate
	kgCoTwo
	totMinOvertime1
	totMinOvertime2
	salaryOvertime1
	salaryOvertime2
	transportTimePaid
	salaryTransport
	carAllowance
	productionFeeAllowance
	computerAllowance
	phoneAllowance
	boxKitAllowance
	totAllowances
	totPerDiem
	lunchPerDiem
	hotelPerDiem
	dinnerPerDiem
	abroadPerDiem
	notSpecifiedLunch
	useMileageToSet
	mileageToSetAllowance
	transportFromHomeToWork
	hours
	minutes
	hoursOvertime
	minutesOvertime
	hoursOvertime1
	minutesOvertime1
	hoursOvertime2
	minutesOvertime2
	hoursTransportTimePaid
	minutesTransportTimePaid
	hoursNight
	minutesNight
	hoursTooEarly
	minutesTooEarly
	strEndNotSpecified
	isInContract
	dayIsNotWorkHoliday
	location {
	  id
	}
	stageDay {
		id
		createdAt
		updatedAt
		projectId
		date
		strTime
		endTime
		actualEndTime
		lunchStrTime
		lunchEndTime
		totMin
		comment
		useHours
		useMileageToSet
		kmToSet
		kmToSetSalary
		kmToSetDescription
		hoursDefined
		kmToSetDefined
		hours
		minutes
		is2NdShooting
		strTime2
		endTime2
		actualEndTime2
		lunchStrTime2
		lunchEndTime2
		is3RdShooting
		strTime3
		endTime3
		actualEndTime3
		lunchStrTime3
		lunchEndTime3
		totMin2
		totMin3
		hours2
		minutes2
		hours3
		minutes3
	}
	user {
		id
		name
    	firstName
		functionName
	}
	encoderId
    encoder {
      id
      name
      firstName
      fullName
    }
	contract {
		useDinnerPerDiem
		useAbroadPerDiem
		useLunchPerDiem
		useHotelPerDiem
		useCarAllowance
		useProductionFeeAllowance
		useComputerAllowance
		usePhoneAllowance
		useBoxKitAllowance
		hotelPerDiem
		dinnerPerDiem
		carAllowanceRate
		productionFeeAllowanceRate
		computerAllowanceRate
		phoneAllowanceRate
		boxKitAllowanceRate
		abroadPerDiem
		lunchPerDiem
	}
	timesheet {
		id
		strDate
		endDate
		week
		totMin
		validated
		comment
		salary
		totMinOvertime		
		totMinNight
		totMinTooEarly
		kgCoTwo
		totMinOvertime1
		totMinOvertime2
		transportTimePaid
		hasNotSubmittedDay
		weeklyTotalOvertime
		weeklyTotalOvertimeStr
		weeklyTotMinOvt
		weeklyTotMinOvt1
		weeklyTotMinOvt2
		weeklySalaryOvt
		weeklySalaryOvt1
		weeklySalaryOvt2
		weeklyCarAllowance
		weeklyComputerAllowance
		weeklyProductionFeeAllowance
		weeklyPhoneAllowance
		weeklyBoxKitAllowance
		dailyTotAllowances
		weeklyTotAllowances
		weeklyTotPerDiem
		hours
		minutes
		hoursOvertime
		minutesOvertime
		hoursOvertime1
		hoursOvertime2
		minutesOvertime1
		minutesOvertime2
		hoursTransportTimePaid
		minutesTransportTimePaid
		hoursNight
		minutesNight
		hoursTooEarly
		minutesTooEarly
		department
		departmentName
		function
		functionName
		weeklyTotMinOvtStr
		weeklyTotMinOvt1Str
		weeklyTotMinOvt2Str
		weeklyLunchPerDiem
		weeklyHotelPerDiem
		weeklyDinnerPerDiem
		weeklyAbroadPerDiem
		encoderId
		encoder {
		  id
		  name
		  firstName
		  fullName
		}
	}
`;

const stage_day_cols = `
	id
	createdAt
	updatedAt
	projectId
	date
	strTime
	endTime
	actualEndTime
	lunchStrTime
	lunchEndTime
	totMin
	comment
	useHours
	useMileageToSet
	kmToSet
	kmToSetSalary
	kmToSetDescription
	hoursDefined
	kmToSetDefined
	hours
	minutes
	is2NdShooting
	strTime2
	endTime2
	actualEndTime2
	lunchStrTime2
	lunchEndTime2
	is3RdShooting
	strTime3
	endTime3
	actualEndTime3
	lunchStrTime3
	lunchEndTime3
	totMin2
	totMin3
	hours2
	minutes2
	hours3
	minutes3
`;
/**
 * @todo
 * Back Julis
 *
 * beforeCallTime
 * dailyBeforeCallTime
 * weeklyBeforeCallTime
 */

/**
 * Validate timesheet
 * @param {Int} id
 */
export const validateTimesheet = async (id) => {
	const mutation = gql`
		mutation ($id: ID!) {
			ValidateTimesheet(TimesheetId: $id) {
				id
			}
		}
	`;
	const {
		data: { ValidateTimesheet }
	} = await apollo.mutate({
		mutation,
		variables: {
			id: parseInt(id, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return ValidateTimesheet;
};

export const getStageDays = async (startDate, endDate) => {
	try {
		const QUERY_GET_STAGE_DAYS = gql`
			query ($StartDate: String, $EndDate: String) {
				GetStageDays(StartDate: $StartDate, EndDate: $EndDate) {
					${res_field}
				}
			}
		`;

		const {
			data: { GetStageDays }
		} = await apollo.query({
			query: QUERY_GET_STAGE_DAYS,
			variables: {
				StartDate: startDate,
				EndDate: endDate
			},
			fetchPolicy: 'no-cache'
		});

		return GetStageDays;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const addUpdStageDay = async (stageDayId = 0, updStageDay = {}) => {
	try {
		const MUTATION_ADD_UPD_STAGE_DAY = gql`
			mutation ($stageDayId: ID!, $updStageDay: StageDayInput!) {
				AddUpdStageDay(StageDayId: $stageDayId, UpdStageDay: $updStageDay) {
					${res_field}
				}
			}
		`;

		const {
			data: { AddUpdStageDay }
		} = await apollo.mutate({
			mutation: MUTATION_ADD_UPD_STAGE_DAY,
			variables: {
				stageDayId: parseInt(stageDayId, 10),
				updStageDay
			}
		});

		return AddUpdStageDay;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const delStageDay = async (stageDayId) => {
	try {
		const MUTATION_DEL_STAGE_DAY = gql`
			mutation ($stageDayId: ID!) {
				DelStageDay(TsDayId: $stageDayId)
			}
		`;

		const {
			data: { DelStageDay }
		} = await apollo.mutate({
			mutation: MUTATION_DEL_STAGE_DAY,
			variables: {
				stageDayId: parseInt(stageDayId, 10)
			}
		});

		return DelStageDay;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getTsDays = async (StartDate) => {
	try {
		const QUERY_GET_TS_DAYS = gql`
			query($StartDate: String) {
				myTsDays(StartDate: $StartDate) {
					${res_field}
				}
			}
		`;

		const {
			data: { myTsDays }
		} = await apollo.query({
			query: QUERY_GET_TS_DAYS,
			variables: {
				StartDate
			},
			fetchPolicy: 'no-cache'
		});

		return myTsDays;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const saveTsDaysForCrew = async (
	TsDayEntryForCrewInput,
	StrTime,
	EndTime,
	LunchStrTime,
	LunchEndTime,
	Lunch,
	Hotel,
	UseDinner,
	UseAbroad,
	DayType,
	LeftHomeAt,
	ReturnedHomeAt,
	NotSpecifiedLunch,
	UseMileageToSet,
	TransportFromHomeToWork,
	StrEndNotSpecified,
	LocationId
) => {
	try {
		const MUTATION_SAVE_TS_DAY_FOR_CREW = gql`
			mutation (
				$TsDayEntryForCrewInput: [TsDayEntryForCrewInput]!
				$StrTime: String
				$EndTime: String
				$LunchStrTime: String
				$LunchEndTime: String
				$Lunch: Boolean
				$Hotel: Boolean
				$UseDinner: Boolean
				$UseAbroad: Boolean
				$DayType: Int
				$LeftHomeAt: String
				$ReturnedHomeAt: String
				$NotSpecifiedLunch: Boolean
				$UseMileageToSet: Boolean
				$TransportFromHomeToWork: Boolean
				$StrEndNotSpecified: Boolean
				$LocationId: ID
			) {
				AddUpdTsDayForUsers(
					TsDayEntryForCrewInput: $TsDayEntryForCrewInput
					StrTime: $StrTime
					EndTime: $EndTime
					LunchStrTime: $LunchStrTime
					LunchEndTime: $LunchEndTime
					Lunch: $Lunch
					Hotel: $Hotel
					UseDinner: $UseDinner
					UseAbroad: $UseAbroad
					DayType: $DayType
					LeftHomeAt: $LeftHomeAt
					ReturnedHomeAt: $ReturnedHomeAt
					NotSpecifiedLunch: $NotSpecifiedLunch
					UseMileageToSet: $UseMileageToSet
					TransportFromHomeToWork: $TransportFromHomeToWork
					StrEndNotSpecified: $StrEndNotSpecified
					LocationId: $LocationId
				) {
					id
					createdAt
					updatedAt
					userId
					date
					user {
						id
						name
						firstName
					}
				}
			}
		`;
		const {
			data: { AddUpdTsDayForUsers }
		} = await apollo.mutate({
			mutation: MUTATION_SAVE_TS_DAY_FOR_CREW,
			variables: {
				TsDayEntryForCrewInput,
				StrTime,
				EndTime,
				LunchStrTime,
				LunchEndTime,
				Lunch,
				Hotel,
				UseDinner,
				UseAbroad,
				DayType,
				LeftHomeAt,
				ReturnedHomeAt,
				NotSpecifiedLunch,
				UseMileageToSet,
				TransportFromHomeToWork,
				StrEndNotSpecified,
				LocationId
			}
		});
		return AddUpdTsDayForUsers;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const saveTsDay = async (
	Date,
	StrTime,
	EndTime,
	LunchStrTime,
	LunchEndTime,
	Lunch,
	Hotel,
	UseDinner,
	UseAbroad,
	DayType,
	LeftHomeAt,
	ReturnedHomeAt,
	NotSpecifiedLunch,
	UseMileageToSet,
	TransportFromHomeToWork,
	UserId,
	StrEndNotSpecified,
	LocationId,
	UseStageDay,
	Remark
) => {
	try {
		const MUTATION_SAVE_TS_DAY = gql`
			mutation (
				$Date: String!
				$StrTime: String!
				$EndTime: String
				$LunchStrTime: String
				$LunchEndTime: String
				$Lunch: Boolean
				$Hotel: Boolean
				$UseDinner: Boolean
				$UseAbroad: Boolean
				$DayType: Int
				$LeftHomeAt: String
				$ReturnedHomeAt: String
				$NotSpecifiedLunch: Boolean
				$UseMileageToSet: Boolean
        		$TransportFromHomeToWork: Boolean
				$UserId: ID
				$StrEndNotSpecified: Boolean
				$LocationId: ID
				$UseStageDay: Boolean
				$Remark: String
			) {
				AddUpdTsDay(
					Date: $Date
					StrTime: $StrTime
					EndTime: $EndTime
					LunchStrTime: $LunchStrTime
					LunchEndTime: $LunchEndTime
					Lunch: $Lunch
					Hotel: $Hotel
					UseDinner: $UseDinner
					UseAbroad: $UseAbroad
					DayType: $DayType
					LeftHomeAt: $LeftHomeAt
					ReturnedHomeAt: $ReturnedHomeAt
					NotSpecifiedLunch: $NotSpecifiedLunch
					UseMileageToSet: $UseMileageToSet
					TransportFromHomeToWork: $TransportFromHomeToWork
					UserId: $UserId
					StrEndNotSpecified: $StrEndNotSpecified
					LocationId: $LocationId
					UseStageDay: $UseStageDay
					Remark: $Remark
				) {
					${res_field}
				}
			}
		`;

		const {
			data: { AddUpdTsDay }
		} = await apollo.mutate({
			mutation: MUTATION_SAVE_TS_DAY,
			variables: {
				Date,
				StrTime,
				EndTime,
				LunchStrTime,
				LunchEndTime,
				Lunch,
				Hotel,
				UseDinner,
				UseAbroad,
				DayType,
				LeftHomeAt,
				ReturnedHomeAt,
				NotSpecifiedLunch,
				UseMileageToSet,
				TransportFromHomeToWork,
				UserId,
				StrEndNotSpecified,
				LocationId,
				UseStageDay,
				Remark
			}
		});

		return AddUpdTsDay;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const submitSelectedTimesheet = async (tsDayId, ForPlanning = false) => {
	try {
		const MUTATION_SUBMIT_SELECTED_TIMESHEET = gql`
			mutation SUBMIT_SELECTED_TIMESHEET($tsDayId: [ID]!, $ForPlanning: Boolean) {
				SubmitSelectedTimesheet(TsDayId: $tsDayId, ForPlanning: $ForPlanning)
			}
		`;

		const {
			data: { SubmitSelectedTimesheet }
		} = await apollo.mutate({
			mutation: MUTATION_SUBMIT_SELECTED_TIMESHEET,
			variables: {
				tsDayId,
				ForPlanning
			}
		});

		return SubmitSelectedTimesheet;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const delTimesheet = async (tsDayId) => {
	try {
		const MUTATION_DEL_TIMESHEET = gql`
			mutation ($TsDayId: ID!) {
				DelTsDay(TsDayId: $TsDayId)
			}
		`;

		const {
			data: { DelTsDay }
		} = await apollo.mutate({
			mutation: MUTATION_DEL_TIMESHEET,
			variables: {
				TsDayId: parseInt(tsDayId, 10)
			}
		});

		return DelTsDay;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getTimesheetDetail = async (TimesheetId) => {
	try {
		const QUERY_GET_TIMESHEET_DETAIL = gql`
			query ($TimesheetId: Int!) {
				TimesheetDetails(TimesheetId: $TimesheetId) {
					${res_field}
				}
			}
		`;

		const {
			data: { TimesheetDetails }
		} = await apollo.query({
			query: QUERY_GET_TIMESHEET_DETAIL,
			variables: {
				TimesheetId: parseInt(TimesheetId)
			},
			fetchPolicy: 'no-cache'
		});

		return TimesheetDetails;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const rejectTsDay = async (tsDayId, comment, ForPlanning = false) => {
	try {
		const MUTATION_REJECT_TS_DAY = gql`
			mutation ($TsDayId: ID!, $Comment: String, $ForPlanning: Boolean) {
				RejectTsDay(TsDayId: $TsDayId, Comment: $Comment, ForPlanning: $ForPlanning) {
					id
				}
			}
		`;

		const {
			data: { RejectTsDay }
		} = await apollo.mutate({
			mutation: MUTATION_REJECT_TS_DAY,
			variables: {
				TsDayId: parseInt(tsDayId),
				Comment: comment,
				ForPlanning
			}
		});

		return RejectTsDay;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const getStageDay = async (date) => {
	const QUERY_GET_STAGE_DAY = gql`
		query ($date: String!) {
			GetStageDay(Date: $date) {
				${stage_day_cols}
			}
		}
	`;

	const {
		data: { GetStageDay }
	} = await apollo.query({
		query: QUERY_GET_STAGE_DAY,
		variables: {
			date
		},
		fetchPolicy: 'no-cache'
	});

	return GetStageDay;
};

export const exportTsDayDetails = async (Date, ContractType, LockPlanning) => {
	try {
		const {
			data: { ExportTsDayDetails }
		} = await apollo.mutate({
			mutation: gql`
				mutation ($Date: String!, $ContractType: Int!, $LockPlanning: Boolean) {
					ExportTsDayDetails(Date: $Date, ContractType: $ContractType, LockPlanning: $LockPlanning)
				}
			`,
			variables: {
				Date,
				ContractType,
				LockPlanning
			}
		});
		return ExportTsDayDetails;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const serviceLockOrUnlockPlanning = async (Date, LockPlanning) => {
	try {
		const {
			data: { LockOrUnlockPlanning }
		} = await apollo.mutate({
			mutation: gql`
				mutation ($Date: String!, $LockPlanning: Boolean!) {
					LockOrUnlockPlanning(Date: $Date, LockPlanning: $LockPlanning)
				}
			`,
			variables: {
				Date,
				LockPlanning
			}
		});
		return LockOrUnlockPlanning;
	} catch (e) {
		throw new Error(e.message);
	}
};

export const addTsDaysUsersForPlanning = async (TsDayEntryForCrewInput = [], CopiedTsdayId = null, OverWrite = false) => {
	try {
		const mutation = gql`
			mutation ($TsDayEntryForCrewInput: [TsDayEntryForCrewInput]!, $CopiedTsdayId: ID, $OverWrite: Boolean) {
				AddUpdTsDayForUsersPlanning(TsDayEntryForCrewInput: $TsDayEntryForCrewInput, CopiedTsdayId: $CopiedTsdayId, OverWrite: $OverWrite) {
					id
					createdAt
					updatedAt
					userId
					date
					user {
						id
						name
						firstName
					}
				}
			}
		`;

		const {
			data: { AddUpdTsDayForUsersPlanning }
		} = await apollo.mutate({
			mutation,
			variables: {
				TsDayEntryForCrewInput,
				OverWrite,
				CopiedTsdayId
			}
		});

		return AddUpdTsDayForUsersPlanning;
	} catch (e) {
		throw new Error(e.message);
	}
};
